<template>
  <main class="page-search-result">
    <div id="printContainer" class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>搜尋結果</el-breadcrumb-item>
        </el-breadcrumb>
        <h4>搜尋結果</h4>
        <section>
          <!-- 側邊選單 -->
          <ul class="list list--aside">
            <li
              class="item"
              :key="item"
              v-for="item in sidebarMenu">
              <router-link
                class="link"
                :class="returnMenuItemClass(item)"
                :to="returnMenuRouterLinkTo(item)">
                {{item}}
              </router-link>
            </li>
          </ul>
          <!-- 後分類選單 -->
          <div class="separate-title"></div>
          <TheFilterPanel
            :dataType="currentDataType">
          </TheFilterPanel>
        </section>
        <section>
          <RecentQuery v-if="isShowRecentQuery">
          </RecentQuery>
        </section>
      </div>
      <div class="container">
        <div
          class="container-hint"
          v-if="isPageLoading==false">
          <div>
            {{getterSearchConditionSummary}}
            <el-popover
              trigger="click"
              width="500">
              <p
                v-for="(text,index) in getterSearchConditionList"
                :key="index">
                {{text}}
              </p>
              <!-- <p>關鍵字：宜誠資訊股份有限公司</p>
              <p>公司類型：ＸＸＸ</p> -->
              <el-button slot="reference" type="text" size="mini">查看目前搜尋條件</el-button>
            </el-popover>
          </div>
          <div
            style="font-size:10px;color:#909399;margin-top:8px"
            v-if="companyCount>10000">
            為讓您享有最佳體驗，至多顯示查詢結果前 10,000 則資訊
          </div>
        </div>
        <div
          v-if="isPageLoading==false&&companyCount<=0">
          <el-button type="primary" icon="el-icon-arrow-left" @click="goHome">點此返回查詢首頁</el-button>
        </div>
        <div
          class="company-list-set"
          style="min-height:100%"
          v-if="isPageLoading||(isPageLoading==false&&companyCount>0)"
          v-loading="isPageLoading||isCompanyListLoading">
          <div class="company-list-toolbar">
            <div class="toolbar-left">
              <el-select
                size="mini"
                placeholder="排序"
                v-model="companyListSort"
                @change="onCompanyListSortChange">
                <el-option
                  v-for="item in companyListSortOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="toolbar-right">
              <el-pagination
                layout="prev, pager, next, jumper"
                :total="companySearchableCount"
                :current-page.sync="currentPage"
                @current-change="searchCompanyList">
              </el-pagination>
            </div>
          </div>
          <CompanyList
            style="min-height:100%"
            :companyList="companyList"
            :queryCondition="currentSearchQuery"
            :isCollections="isCollections">
          </CompanyList>
          <div class="align-right">
            <el-pagination
              layout="prev, pager, next, jumper"
              :total="companySearchableCount"
              :current-page.sync="currentPage"
              @current-change="searchCompanyList">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <MainPageFloatButton
      :downloadAction="downloadAction">
    </MainPageFloatButton>
  </main>
</template>

<script>
import moment from 'moment'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import * as codes from '@/const/codes.ts'
import recentQueryStorage from '@/storage/recentQueryStorage.js'
import routerService from '@/utils/routerService.js'
import utils from '@/utils/utils.js'
import companyListSortOptions from '@/const/companyListSortOptions.js'
import CompanyList from '@/components/CompanyList/CompanyList.vue'
import TheFilterPanel from '@/components/TheFilterPanel/TheFilterPanel.vue'
import RecentQuery from '@/components/RecentQuery/RecentQuery.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'
import parseCompanyListExcel from '@/utils/parseCompanyListExcel.js'

export default {
  props: {
    // query.params
    'dataTypeIndex': {
      default () {
        // return codes.DATA_TYPE_INDEX_DIST[codes.DEFAULT_DATA_TYPE]
        return 'default'
      }
    }
  },
  components: {
    CompanyList,
    TheFilterPanel,
    RecentQuery,
    MainPageFloatButton
  },
  data () {
    return {
      isShowRecentQuery: false,
      companyListSortOptions,
      companyListSort: codes.DEFAULT_COMPANY_LIST_SORT_VALUE,
      currentQuery: {},
      recentQuery: [],
      filterSampleCheckbox: [],
      companyTags: [
        '紡織',
        '傳產',
        '創新研發'
      ],
      addNewTagInput: '',
      addNewTagInputVisible: false,
      currentPage: 1
      // isPageLoading: false,
      // isCompanyListLoading: false,
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
    ...mapState('companyList', [
      'currentSearchQuery',
      // 'originSearchQuery',
      'companyList',
      'companyCount',
      'isCollections',
      'companySearchableCount',
      'isPageLoading',
      'isCompanyListLoading',
      'sidebarMenu'
    ]),
    ...mapGetters('companyList', [
      'getterSearchConditionSummary',
      'getterSearchConditionList'
    ]),
    currentDataType () {
      if (this.dataTypeIndex === 'default') {
        return this.sidebarMenu[0]
      } else if (this.dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['公司']) {
        return '公司'
      } else {
        return '商業登記'
      }
    }
    // sidebarMenu () {
    //   if (this.$route.query &&
    //   this.$route.query.originQuery &&
    //   this.$route.query.originQuery.basic &&
    //   this.$route.query.originQuery.basic.data_type) {
    //     // 如首頁選擇公司類型，則只顯示該類型的選單
    //     return [this.$route.query.originQuery.basic.data_type]
    //   } else {
    //     return ['公司', '商業登記']
    //   }
    // }
    // searchConditionSummary () {
    //   return `根據您所設定搜尋 ${this.currentSearchQuery.basic.keyword || ''} 之條件，共獲得 ${this.companyCount} 筆資料。`
    // },
    // searchConditionList () {
    //   let textArr = []
    //   if (this.currentSearchQuery.basic) {
    //     if (this.currentSearchQuery.basic.keyword) {
    //       textArr.push(`關鍵字：${this.currentSearchQuery.basic.keyword}`)
    //     }
    //     if (this.currentSearchQuery.basic.keyword_type) {
    //       textArr.push(`關鍵字類型：${this.currentSearchQuery.basic.keyword_type}`)
    //     }
    //   }
    //   if (this.currentSearchQuery.filter && this.currentSearchQuery.filter.length) {
    //     this.currentSearchQuery.filter.forEach(item => {
    //       if (item._info && item._info.length) {
    //         item._info.forEach(d => {
    //           const text = `${codes.FILTER_TYPE_TITLE_DIST[d.type]}：${d.key}`
    //           textArr.push(text)
    //         })
    //       }
    //     })
    //   }
    //   return textArr
    // }
  },
  methods: {
    ...mapActions('companyList', [
      'actionInitSearchPage',
      'actionSearchPage',
      'actionCompanyList'
    ]),
    ...mapMutations('companyList', [
      // 'mutationOriginSearchQuery',
      // 'mutationCurrentSearchQuery'
    ]),
    returnMenuItemClass (dataType) {
      return { active: dataType === this.currentDataType }
    },
    returnMenuRouterLinkTo (item) {
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      // 將目前的current還原為首頁設定的條件
      query.currentQuery = JSON.parse(JSON.stringify(this.originRouteQuery.originQuery))
      return {
        name: 'companyList',
        params: {
          dataTypeIndex: codes.DATA_TYPE_INDEX_DIST[item] // 修改為所點選的data_type
        },
        query
      }
    },
    goHome () {
      // this.$router.push({ name: 'home' })
      routerService('push', { name: 'home' })
    },
    onCompanyListSortChange (sortValue) {
      // let query = JSON.parse(JSON.stringify(this.$route.query))
      // let currentQuery = JSON.parse(query.currentQuery)
      // currentQuery.basic['sort'] = JSON.parse(sortValue)
      // query.currentQuery = JSON.stringify(currentQuery)
      // this.$router.push({
      //   name: 'companyList',
      //   query
      // })
      let searchRequest = JSON.parse(JSON.stringify(this.currentSearchQuery))
      searchRequest.basic['sort'] = JSON.parse(sortValue)
      searchRequest.basic['page-index'] = 1
      this.currentPage = searchRequest.basic['page-index'] // 更新頁碼
      this.actionCompanyList(searchRequest)

      // 更新網址
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      query.currentQuery = JSON.stringify(searchRequest)
      // this.$router.replace({
      //   name: 'companyList',
      //   query
      // })
      routerService('replace', {
        name: 'companyList',
        query
      })
    },
    // 查詢公司列表（切換頁碼）
    searchCompanyList (pageNo) {
      // let query = JSON.parse(JSON.stringify(this.$route.query))
      // let currentQuery = JSON.parse(query.currentQuery)
      // currentQuery.basic['page-index'] = pageNo
      // query.currentQuery = JSON.stringify(currentQuery)
      // this.$router.push({
      //   name: 'companyList',
      //   query
      // })
      let searchRequest = JSON.parse(JSON.stringify(this.currentSearchQuery))
      searchRequest.basic['page-index'] = pageNo
      this.currentPage = searchRequest.basic['page-index'] // 更新頁碼
      this.actionCompanyList(searchRequest)

      // 更新網址
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      query.currentQuery = JSON.stringify(searchRequest)
      // this.$router.replace({
      //   name: 'companyList',
      //   query
      // })
      routerService('replace', {
        name: 'companyList',
        query
      })
    },
    // 下載
    async downloadAction () {
      try {
        let searchQuery = JSON.parse(JSON.stringify(this.currentSearchQuery))
        searchQuery.basic.get = ['list']
        searchQuery.basic['page-index'] = 1
        searchQuery.basic['page-size'] = 10000

        const result = await apis.companyListGet(searchQuery)
        if (!result.success || !result.payload || !result.payload.list) {
          return false
        }

        const addressEnResult = await apis.addressTranslateBatch({
          adds: result.payload.list.map(d => d.company_address)
        })
        if (!addressEnResult.success || !addressEnResult.payload) {
          return false
        }
        const addressEnList = addressEnResult.payload.map(d => {
          return d.success ? d.data : ''
        })
        
        // let thisYear = moment().year() - 1911
        // let thisYear = moment().year()
        // let lastYear = thisYear - 1
        // let theYearBeforLast = lastYear - 1

        await import('@/vendor/Export2Excel.js').then(excel => {

          const { data, header } = parseCompanyListExcel(result.payload.list, addressEnList)
          
          // const dateString = moment().format('YYYY-MM-DD')
          // let searchCondition = ''
          // if (searchQuery.basic.keyword) {
          //   searchCondition = `「${searchQuery.basic.keyword}」`
          // }

          excel.export_json_to_excel({
            header, //表头 必填
            data, //具体数据 必填
            filename: `${moment().format('YYYY_MM_DD_HH_mm')}`, //非必填
            autoWidth: true, //非必填
            bookType: 'xlsx' //非必填
          })

          return
        })

        return true
      } catch (e) {
        console.error(e)
      }
    }
  },
  async created () {
    // console.log('created')
    // 將首頁設定的查詢條件紀錄起來
    if (this.originRouteQuery && this.originRouteQuery.currentQuery) {
      // 將首頁設定的查詢條件紀錄起來
      // let originQuery = JSON.parse(this.$route.query.originQuery)
      // this.mutationOriginSearchQuery(originQuery)

      // 開啟頁面初次查詢
      let originQuery = JSON.parse(this.originRouteQuery.currentQuery)
      this.currentPage = originQuery.basic['page-index'] || 1 // 更新頁碼
      await this.actionInitSearchPage(originQuery)

      // -- 紀錄最近查詢 --
      try {
        let keyword = ''
        if (originQuery.basic && originQuery.basic.keyword) {
          keyword = originQuery.basic.keyword
        } else {
          return // 排除掉無關鍵字的查詢
        }
        // 存入localStorage
        recentQueryStorage.insert(keyword, this.$route)
        // 顯示
        this.isShowRecentQuery = true
      }
      catch (e) {
      }
    }
  },
  watch: {
    // '$route.query': {
    'originRouteQuery': {
      async handler (d) {
        if (d.currentQuery) {
          let searchRequest = JSON.parse(d.currentQuery)
          searchRequest.basic.data_type = this.currentDataType
          this.actionSearchPage(searchRequest)

          // // 切換頁
          // if (searchRequest.basic['page-index'] &&
          // searchRequest.basic['page-index'] != this.currentSearchQuery.basic['page-index']) {
          //   this.currentPage = searchRequest.basic['page-index'] // 更新頁碼
          //   this.actionCompanyList(searchRequest)
          // // 全部查詢
          // } else {
          //   this.actionSearchPage(searchRequest)
          // }
        }
      }
    },
    'dataTypeIndex': {
      async handler (d) {
        if (this.originRouteQuery.currentQuery) {
          let searchRequest = JSON.parse(this.originRouteQuery.currentQuery)
          searchRequest.basic.data_type = this.currentDataType
          // 更新頁碼
          if (searchRequest.basic && searchRequest.basic['page-index']) {
            this.currentPage = searchRequest.basic['page-index']
          }
          // 查詢
          await this.actionSearchPage(searchRequest)
        }
      },
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.dataTypeIndex) {
        routerService('replace', { name: 'home' })
        return
      }
      // 如果網址參數是原始參數的話，重新再導向到短網址
      if (vm.$route.query.originQuery && vm.$route.query.currentQuery) {
        routerService('replace', {
          name: 'companyList',
          params: vm.$route.params,
          query: vm.$route.query
        })
        return
      }
      if (!vm.$route.query.query) {
        routerService('replace', { name: 'home' })
        return
      }
    })
  }
}
</script>
